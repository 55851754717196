import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, ActivityIndicator, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { FontAwesome, SimpleLineIcons } from "@expo/vector-icons";
import * as Progress from 'react-native-progress';
import navigationService from '../services/navigation-service';


interface State {
  progress: number;
}

interface Props {
  adiacentMessage?: string;
  isSecondary?: boolean;
  progress?: boolean;
  circleTime?: number
  isBusy?: boolean
  label: string;
  icon?: any;
  align?: 'left' | 'right';
  onPress?: any
  style?: ViewStyle
}

class PrimaryButton extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  interval: any

  constructor(props: any) {
    super(props);
    this.state = { progress: -1 }

  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.isBusy !== this.props.isBusy && this.props.isBusy && this.props.progress) {
      this.setState({ progress: 0 })
      let seconds = 0;
      this.interval = setInterval(() => {
        // console.log("progress", this.state.progress)
        //set progress from 0 to 1 in 0.1 seconds with 1 being 25 seconds
        seconds += 1;
        this.setState({ progress: seconds / (this.props.circleTime || 45) })
        if (seconds >= (this.props.circleTime || 45)) {
          clearInterval(this.interval)
          this.setState({ progress: 1 })
        }
      }, 1000)
    }
    if (prevProps.isBusy !== this.props.isBusy && !this.props.isBusy && this.props.progress && this.interval) {
      clearInterval(this.interval);
      this.setState({ progress: 1 })
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  render() {
    const busyIndicator = () => {
      if (this.props.isBusy) {
        return this.props.progress && Platform.OS !== 'web' ?
          <View style={[style.row, style.fullHeight, style.vcenter, {
            position: 'absolute',
            right: 0,
            paddingLeft: 10
          }]}>
            <Progress.Pie borderColor={'#ffffff'} borderWidth={0} animated={true} unfilledColor={"#ffffff"} color={colors.greaysh} progress={this.state.progress} size={25} />

          </View> : <View style={[style.row, style.fullHeight, style.vcenter, {
            position: 'absolute',
            right: 0,
            paddingLeft: 10
          }]}>

            <ActivityIndicator size="small" color={colors.iconColorWhite} />

          </View>

      }
    }
    return (
      <TouchableOpacity disabled={this.props.isBusy} onPress={this.props.onPress} style={[this.props.style, this.props.isSecondary ? null : {

      }]}>
        <View

          style={{ padding: 6, backgroundColor: this.props.isSecondary ? colors.blueMarin : colors.primaryColor, borderRadius: colors.borderRadius, borderColor: this.props.isSecondary ? 'transparent' : 'transparent', borderWidth: 0 }}
        >
          <View style={[style.column, style.hcenter, style.vcenter]}>
            <View style={[style.row, style.fullWidth, style.vcenter, {
              padding: 4,
              flexDirection: this.props.align === 'right' ? 'row-reverse' : 'row'
            }]}>
              {this.props.icon ? <View style={{ width: 30 }}>
                {this.props.icon}
              </View> : null}
              <View style={{ flex: 1 }}>


                <Text style={{
                  fontSize: this.props.adiacentMessage ? 14 : 16,
                  fontFamily: 'Jost',
                  textAlign: 'center',
                  color: this.props.isSecondary ? colors.whiteish : colors.whiteish
                }}>{this.props.label} </Text>
              </View>
              {this.props.icon ? <View style={{ width: 30 }}>

              </View> : null}
            </View>
            {this.props.adiacentMessage ? <Text style={{
              fontSize: 13,
              fontFamily: 'Jost',
              textAlign: 'center',
              color: this.props.isSecondary ? colors.primaryColor : '#fff'
            }}>{this.props.adiacentMessage}</Text> : null}
            {busyIndicator()}
          </View>
        </View>
      </TouchableOpacity>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default PrimaryButton;

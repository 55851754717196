import {
  ActivityIndicator,
  Alert,
  AsyncStorage,
  BackHandler,
  Text,
  Linking,
  StatusBar,
  SafeAreaView,
  TextInput,
  TouchableOpacity, Platform, View
} from 'react-native';
import colors from "./src/constants/colors";
import 'react-native-gesture-handler';
import { AppContext, AppProvider } from "./src/store/app-provider";
import AppNavigator from "./src/components/navigator";
import { ToastProvider } from "react-native-toast-notifications";
import { Amplify } from "aws-amplify";
import config from './src/aws-exports';
import navigationService from "./src/services/navigation-service";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import style from "./src/constants/style";
import { AntDesign, Feather, FontAwesome, FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import PrimaryButton from "./src/components/primary-button";
import ReactGA from "react-ga4";
import { useAssets } from "expo-asset";
import LinearGradientWeb from 'react-native-web-linear-gradient';
import * as Font from 'expo-font';
import { Asset } from 'expo-asset';
ReactGA.initialize("G-E9GPKNNSJH");
//global.Buffer = Buffer;
declare var global: any;
if (typeof global.crypto !== 'object') {
  global.crypto = {
    getRandomValues: (array: any[]) => array.map(() => Math.floor(Math.random() * 256)),
  };
}

export default function AppWeb() {

  Font.loadAsync({
    // Load Ionicons
    ...FontAwesome.font,
    ...FontAwesome5.font,
    ...MaterialIcons.font,
    ...Feather.font,


  });
  Font.loadAsync({
    'Jost': Asset.fromModule(require('./src/assets/fonts/Jost.ttf')).uri,
    'Oleo': Asset.fromModule(require('./src/assets/fonts/Oleo.ttf')).uri
  });

  navigationService.props.LinearGradient = LinearGradientWeb;

  if (Platform.OS === 'web') {
    //console.log("window.location.href", window.location.href)
    config.oauth.redirectSignOut = config.oauth.redirectSignIn = window.location.href.indexOf("lyritunes") !== -1 ? "https://www.lyritunes.com/" : "http://localhost:19006/"
  } else {
    const backAction = () => {
      if (!navigationService.props.setHome) {
        //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen('Home', {}, false));
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
        BackHandler.removeEventListener('hardwareBackPress', backAction);
        return true;
      }
      return true;
    }
    BackHandler.addEventListener('hardwareBackPress', backAction);
    StatusBar.setBarStyle('light-content', true)
    StatusBar.setBackgroundColor(colors.primaryColor);
    config.oauth.redirectSignOut = config.oauth.redirectSignIn = "lyritunes://"
  }

  Amplify.configure({
    ...config,
    Analytics: {
      disabled: true,
    },
  });
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#ffffff" }}>
      <AppProvider Toast={null}>

        <ToastProvider>
          <AppNavigator></AppNavigator>

        </ToastProvider>


      </AppProvider>
    </SafeAreaView>

  );
}


import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { AntDesign, FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { SheetManager } from 'react-native-actions-sheet';
import PrimaryButton from './primary-button';
interface State {
    appMenu?: any
}
interface Props {
    style?: ViewStyle
}
class AppMenu extends React.Component<Props, State>  {
    static contextType = AppContext;
    declare context: IContext

    constructor(props: any) {
        super(props);
        /*  this.state = {
             appMenu: [{
                 label: "Stats",
                 icon: "google-analytics",
                 type: "material"
             }, {
                 label: "Buy Premium",
                 icon: "shopping-cart"
             }, {
                 label: "Terms and Conditions",
                 icon: "file-alt"
             }, {
                 label: "Privacy Policy",
                 icon: "file-alt"
             }]
         } */
        this.state = {
            appMenu: [{
                label: "Stats",
                icon: "google-analytics",
                type: "material"
            }, {
                label: "Terms and Conditions",
                icon: "file-alt"
            }, {
                label: "Privacy Policy",
                icon: "file-alt"
            }]
        }

    }
    itemPress = async (label: string) => {
        this.context.setAppProp({ appMenuOpened: false, appMenuOpenedBack: false })
        switch (label) {
            case "News":
                if (Platform.OS === "web" && !this.context.state.isAdmin) {
                    SheetManager.show('download_sheet_home');
                    return;
                }
                if (!this.context.state.isPremiumSubscriber && !this.context.state.isAdmin) {
                    SheetManager.show('go_premium_sub');
                }
                if ((Platform.OS !== "web" && this.context.state.isPremiumSubscriber) || this.context.state.isAdmin) {
                    this.context.setScreen('News', true);
                }
                break;
            case "Stats":
                this.context.setScreen('Stats', true);
                break;
            case "Promoters":
                this.context.setScreen('Promoters', true);
                break;
            case "Terms and Conditions":
                SheetManager.hide("menu_sheet");
                this.context.setScreen('Terms', true);
                break;
            case "Privacy Policy":
                SheetManager.hide("menu_sheet");
                this.context.setScreen('Privacy', true);
                break;
            case 'Buy Premium':
                if (Platform.OS === "web" && !this.context.state.isAdmin) {
                    SheetManager.show('download_sheet_home');
                    return;
                }
                await SheetManager.hide("menu_sheet");
                setTimeout(() => {
                    SheetManager.show('buy_premium');
                }, 500)

                break
            case 'Premium Subscribe':
                await SheetManager.hide("menu_sheet");
                setTimeout(() => {
                    SheetManager.show('buy_premium_subscribe');
                }, 500)

                break
            case 'My Liked':
                this.context.setScreen('MyLiked', true);
                break
            case 'My Posts':
                this.context.setScreen('MyPosts', true);
                break
            case 'My Account':
                this.context.setScreen('MyAccount', true);
                break

        }
    }

    componentDidMount() {
        if ((Platform.OS !== "web" || this.context.state.isAdmin) && this.context.state.appConfig?.showPromoters) {
            //insert first item
            this.state.appMenu.splice(0, 0, {
                label: "Promoters",
                icon: "user-astronaut",
                screen: "Promoters"
            })

        }


        this.setState({ appMenu: [...this.state.appMenu] })


    }


    render() {

        return (
            <View style={[this.props.style, style.column, { backgroundColor: colors.backgroundColor, borderTopRightRadius: 30, borderBottomRightRadius: 30, paddingTop: 20, paddingLeft: 10, paddingRight: 10 }]}>
                {this.state.appMenu.map((item, index) => {
                    return <TouchableOpacity key={index} onPress={() => {
                        this.itemPress(item.label)
                    }}>
                        <View style={[style.row, style.fullWidth, style.vcenter, { padding: 10 }]}>
                            <View style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, borderRadius: 19, backgroundColor: colors.primaryColor }]}>
                                {item.type === "material" ? <MaterialCommunityIcons name={item.icon} size={20} color={colors.whiteish} /> : <FontAwesome5 name={item.icon} size={20} color={colors.whiteish} />}
                            </View>
                            <Text style={[{ marginLeft: 15, color: colors.whiteish, fontFamily: 'Jost', fontSize: 19 }]}>{item.label}</Text>
                        </View>
                    </TouchableOpacity>
                })}
                <View style={{ flex: 1 }}></View>

            </View>
        )
    };

}


const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});



export default AppMenu;

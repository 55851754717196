import { Entypo, Feather, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { Dimensions, Platform, SafeAreaView, TextInput } from 'react-native';
import {
  NativeStackHeaderProps,
  NativeStackNavigationOptions,
  NativeStackNavigationProp
} from '@react-navigation/native-stack';
import { ParamListBase, Route } from '@react-navigation/routers';
import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from '../services/navigation-service';
import { AppContext, IContext, IAppState } from '../store/app-provider';
import UserButton from "./user-button";
import { SheetManager } from "react-native-actions-sheet";
import GradientText from './gradient-text';

interface State {
  opacity?: number,
}

class Header extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  title: string = "";
  backScreen: string = "";

  constructor(props: any) {
    super(props);
    this.state = { opacity: Platform.OS === 'web' ? 1 : 1 }
    this.title = props.route.name

    //this.backScreen = (this.props.app.screen !== "Home") ? "true" : ""

    //(props.back?.title || "")
    // && Platform.OS !== "web";
  }

  gotoProfile() {
    return;
    if (!this.context.state.user) {
      this.context.setScreen("Sign In", true);
    } else {
      SheetManager.show(`user_menu`);
    }
  }

  gotoHome(header?: boolean) {
    this.context.setScreen("Home", true);
  }

  goBack() {
    //navigationService.pop();
    this.gotoHome()
  }

  goBackBack() {
    navigationService.pop();

  }
  shareApp() {
    const appUrl = Platform.OS === 'android' ? this.context.state.appConfig?.androidApp : this.context.state.appConfig?.iOSApp
    this.context.shareMe(appUrl);
    this.context.logEvent("share_app");

  }
  openMenu() {
    SheetManager.show("menu_sheet");
  }
  componentDidMount(): void {
    /*  setTimeout(() => {
       this.setState({ opacity: 1 })
     }, 2000) */
  }
  componentWillUnmount() {

  }

  render() {
    //console.log("render", this.props)
    return (
      <SafeAreaView>
        <View style={[{

          height: Platform.OS === 'web' ? 70 : 70,
          width: '100%',
          marginTop: 0,
          display: "flex",
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: colors.backgroundColor,
          paddingBottom: Platform.OS === 'web' ? 0 : 0,
          paddingLeft: 10,
          paddingRight: 10
        }]}>


          <View style={[{
            opacity: this.state.opacity,
            height: '100%',
            width: 100,
          }, style.row, style.vcenter, style.hleft]}>
            {(this.context.state.screen && this.context.state.screen === "Home") || !this.context.state.screen ? <TouchableOpacity onPress={() => {
              this.context.setAppProp({ appMenuOpened: true, appMenuOpenedBack: true })
            }}>
              <View style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, borderRadius: 19, backgroundColor: colors.backgroundColor }]}>
                <Feather name="menu" size={20} color={colors.whiteish} />
              </View>
            </TouchableOpacity> : null}

            {this.context.state.screen && this.context.state.screen !== "Home" ?

              <TouchableOpacity onPress={this.goBack.bind(this)}>
                <View style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, borderRadius: 19, backgroundColor: colors.backgroundColor }]}>
                  <Feather name="home" size={20} color={colors.whiteish} />
                </View>
              </TouchableOpacity>
              : null}
          </View>
          <TouchableOpacity style={{ flex: 1, display: 'flex', opacity: this.state.opacity, justifyContent: 'center', alignItems: 'center' }} onPress={this.goBack.bind(this)}>
            <View style={[style.row, style.vcenter, {}]}>
              <Text style={{ fontSize: 38, color: colors.primaryColor, fontFamily: 'Oleo' }}>Wordz</Text>
              <Text style={{ fontSize: 38, color: colors.secondaryColor, fontFamily: 'Oleo' }}>Genie</Text>
            </View>
          </TouchableOpacity>
          <View style={[{ width: 100, height: '100%', opacity: this.state.opacity }, style.row, style.vcenter, style.hright]}>
            {this.context.state.appConfig?.hasReview ? <TouchableOpacity onPress={() => { this.shareApp() }}>
              <View style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, borderRadius: 19, backgroundColor: colors.backgroundColor }]}>
                <Entypo name="share" size={20} color={colors.whiteish} />
              </View>
            </TouchableOpacity> : null}
          </View>

        </View>
      </SafeAreaView>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});
//export default Header;
type Props = {
  back?: {
    title: string;
  },
  title: string,
  t: any,
  options: NativeStackNavigationOptions,
  route: Route<string>;
  navigation: NativeStackNavigationProp<ParamListBase>,
  user: any,
  userPicture: any,
  app: any
}

export default Header

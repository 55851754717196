import { NavigationContainer } from "@react-navigation/native";

import navigationService from "../services/navigation-service";
import { AppContext, IContext } from "../store/app-provider";
import HomeScreen from './../screens/home';
import Toast from 'react-native-toast-notifications';
import * as Animatable from 'react-native-animatable';
import PrivacyScreen from './../screens/privacy';
import TermsScreen from './../screens/terms';
import GameScreen from './../screens/game';
import StatsScreen from './../screens/stats';

import LoginScreen from './../screens/login';
import { ToastProvider } from "react-native-toast-notifications";
import Header from './../components/header';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Constants from 'expo-constants';
const Stack = createNativeStackNavigator();
import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Auth, Hub } from "aws-amplify";
import React from "react";
import colors from "../constants/colors";
import {
  AsyncStorage,
  Keyboard,
  Modal,
  Platform,
  BackHandler,
  ScrollView,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  StatusBar,
  ImageBackground
} from "react-native";
import style from "../constants/style";
import { AntDesign, Entypo, FontAwesome, FontAwesome5, SimpleLineIcons } from "@expo/vector-icons";
import PrimaryButton from "./primary-button";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import Share from "./share";
import Alert from "./alert";
import AppMenu from "./app-menu";
import RadioGroup from "./radio-group";
import DoubleButtonBar from "./double-button-bar";

const slideInLeft = {
  from: {
    transform: [{ translateX: -280 }], // Starts offscreen 100 px to the left
  },
  to: {
    transform: [{ translateX: 0 }], // Ends at its natural position
  },
};

interface State {
  isBusy?: boolean;
  selectedLyricsTab: number;
}

interface Props {

}

const link = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {

      Home: {

        path: '/',
      },
      Privacy: {

        path: 'privacy',
      },
      Game: {

        path: 'game',
      },
      Terms: {

        path: 'terms',
      }
      ,
      Stats: {

        path: 'stats',
      }
    },

  },
}

class AppNavigator extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  appMenu = Platform.OS !== 'web' ? [{
    label: "Premium Subscribe",
    icon: "shopping-cart"
  }, {
    label: "Buy Premium",
    icon: "shopping-cart"
  }, {
    label: "Terms and Conditions",
    icon: "file-alt"
  }, {
    label: "Privacy Policy",
    icon: "file-alt"
  }] : [{
    label: "Terms and Conditions",
    icon: "file-alt"
  }, {
    label: "Privacy Policy",
    icon: "file-alt"
  }]

  menu = [{ label: "My Posts", icon: "book", screen: "MyPosts" }, {
    label: "My Liked",
    icon: "heart",
    screen: "MyLiked"
  }, {
    label: "My Account",
    icon: "user-circle",
    screen: "MyAccount"
  }]

  constructor(props: any) {
    super(props);
    this.state = {
      isBusy: false,
      selectedLyricsTab: 0
    }


  }

  componentDidMount() {

    const url = Linking.getInitialURL().then(url => {
      if (url) {
        const route = url.replace(/.*?:\/\//g, "");
        const routeSplit = route.split("?");
        if (routeSplit[0] === "view") {
          const id = routeSplit[1].split("=")[1];
          //navigationService.navigate("View", { id: id });
          this.context.setScreen("View", true, { id });
        }
        console.log("init route", route)
      }
    });
  }





  gotoMyScreen = (item: any) => {
    SheetManager.hide('user_menu');
    if (item.screen !== 'Feedback') {
      this.context.setScreen(item.screen, true);
    } else {
      setTimeout(() => {
        SheetManager.show(`feedback`);
        //this.context.showToast(`Feedback is not available on the mobile app`, 'success');
      }, 500);
    }
  }

  agreePolicy() {
    this.context.setAppProp({ hasShownPolicy: true });
    if (Platform.OS === "web") {
      localStorage.setItem("hasShownPolicy2", "true");
    } else {
      AsyncStorage.setItem("hasShownPolicy2", "true");
    }
  }


  navigationStateChange = (state: any) => {
    console.log("nav state", state)
    const screen = state?.routes?.[state.routes?.length - 1]?.name;
    const params = state?.routes?.[state.routes?.length - 1]?.params;
    this.context.setScreen(screen, false, params);
    console.log("navigationStateChange", screen, params);
    //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen(screen, params, true));

  }
  readyNav = () => {

    this.navigationStateChange(navigationService.getNavigation().getState())

  }

  render() {

    return (

      <NavigationContainer linking={link} onReady={this.readyNav.bind(this)} onStateChange={this.navigationStateChange.bind(this)} ref={navigatorRef => {
        navigationService.setTopLevelNavigator(navigatorRef);
      }}>

        <Stack.Navigator screenOptions={{
          animationDuration: 500,
          animation: 'simple_push',
          gestureEnabled: true,
          animationTypeForReplace: 'push'

        }}>
          <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{ headerTitleAlign: 'center', title: "WordzGenie", header: (props) => <Header {...props} /> }}
          />







          <Stack.Screen
            name="Privacy"
            component={PrivacyScreen}
            options={{ headerTitleAlign: 'center', title: "Privacy Policy", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Stats"
            component={StatsScreen}
            options={{ headerTitleAlign: 'center', title: "Stats", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="Terms"
            component={TermsScreen}
            options={{
              headerTitleAlign: 'center',
              title: "Terms and Conditions",
              header: (props) => <Header {...props} />
            }}
          />
          <Stack.Screen
            name="Game"
            component={GameScreen}
            options={{
              headerTitleAlign: 'center',
              title: "Game",
              header: (props) => <Header {...props} />
            }}
          />

        </Stack.Navigator>
        <Toast ref={(ref: any) => global['toast'] = ref} />

        {this.context.state.appMenuOpenedBack ? <TouchableOpacity onPress={() => {

          this.context.hideMenu();

        }} style={[style.fullSize, { position: 'absolute', top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0, backgroundColor: 'rgba(0,0,0,0.3)' }]}>

        </TouchableOpacity> : null}
        {this.context.state.appMenuOpened ? <Animatable.View duration={300} ref={ref => navigationService.props.menuRef = ref} style={{ position: 'absolute', height: '100%', width: 280, top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0 }} animation={slideInLeft}>{this.context.state.appMenuOpened ? <AppMenu style={{ width: '100%', height: '100%' }} /> : null}</Animatable.View> : null}
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="sign_sheet_home">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={
              <FontAwesome5 name="user" size={24} color={colors.iconColorWhite} />} style={{ width: '100%' }} onAccept={() => {
                this.context.setScreen('Sign In', true);
                SheetManager.hide('sign_sheet_home')
              }} message={"You need to be signed in to be able to create a new lyrics!"} acceptLabel={"Sign In"} />
          </View>

        </ActionSheet>

        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_ad">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Buy Premium')} onCancel={async () => {
              await SheetManager.hide('go_ad')

              setTimeout(() => {
                SheetManager.show('buy_premium');
              }, 100);
            }} onAccept={() => {
              SheetManager.hide('go_ad');
              this.context.playRewardedAdAndGenerate();

            }} message={('Buy the Premium package or play a 30 seconds rewarded video ad')} acceptLabel={('Play Ad')} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_premium_sub">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Cancel')} onCancel={async () => {
              SheetManager.hide('go_premium_sub')
            }} onAccept={async () => {
              await SheetManager.hide('go_premium_sub')
              setTimeout(() => {
                SheetManager.show('buy_premium')
              }, 100);

            }} message={('This feature is available only for premium subscribers')} acceptLabel={`Subscribe`} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="buy_premium">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <ImageBackground source={{ uri: navigationService.props.back }} imageStyle={{
              borderRadius: 16
            }} resizeMethod={'resize'} style={[style.fullWidth, style.column, style.hcenter, { padding: 15, height: 170 }]}>
              <Text style={{
                marginLeft: 0,
                width: '100%',
                fontSize: 14,
                marginBottom: 10,
                fontFamily: 'Jost'
              }}>Premium package - Purchased for life, removes all ads from the app. No access to premium features</Text>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.buyPremium();
                }, 100);
              }} adiacentMessage={`$${this.context.state.appConfig?.[Platform.OS.toString() + "_" + "premiumPrice1"]}/life none-renewable`} label="Buy Premium"></PrimaryButton>
            </ImageBackground>
            <ImageBackground source={{ uri: navigationService.props.back }} imageStyle={{
              borderRadius: 16

            }} resizeMethod={'resize'} style={[style.fullWidth, style.column, style.hcenter, { padding: 15, height: 170, marginTop: 10 }]}>
              <Text style={{
                marginLeft: 0,
                width: '100%',
                fontSize: 14,
                marginBottom: 10,
                fontFamily: 'Jost'
              }}>Monthly Premium Subscription - Monthly auto-renewable subscription, removes all ads from the app and has access to all premium features</Text>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.buyPremiumSubscription(false);
                }, 100);
              }} adiacentMessage={`$${this.context.state.appConfig?.[Platform.OS.toString() + "_" + "premiumPSubPrice1"]}/month auto-renewable`} label="Subscribe"></PrimaryButton>
            </ImageBackground>
            <ImageBackground source={{ uri: navigationService.props.back }} imageStyle={{
              borderRadius: 16

            }} resizeMethod={'resize'} style={[style.fullWidth, style.column, style.hcenter, { padding: 15, height: 170, marginTop: 10 }]}>
              <Text style={{
                marginLeft: 0,
                width: '100%',
                fontSize: 14,
                marginBottom: 10,
                fontFamily: 'Jost'
              }}>Yearly Premium Subscription - Yearly auto-renewable subscription, removes all ads from the app and has access to all premium features</Text>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.buyPremiumSubscription(true);
                }, 100);
              }} adiacentMessage={`$${this.context.state.appConfig?.[Platform.OS.toString() + "_" + "premiumPSubPriceYearlyMonthly1"]}/month $${this.context.state.appConfig?.[Platform.OS.toString() + "_" + "premiumPSubPriceYearly1"]}/year auto-renewable`} label="Subscribe"></PrimaryButton>
            </ImageBackground>
            <PrimaryButton onPress={async () => {
              await SheetManager.hide('buy_premium');
              setTimeout(() => {
                this.context.restorePurchase();
              }, 100);
            }} label="Restore Purchase" style={{ marginTop: 10, width: '100%' }} isSecondary={true}></PrimaryButton>
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="buy_premium_subscribe">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Restore')} onCancel={() => {
              this.context.restorePurchase();
              SheetManager.hide('buy_premium_subscribe');
            }} icon={
              <FontAwesome size={20} color={'#ffffff'} name='shopping-cart'></FontAwesome>} style={{ width: '100%' }} onAccept={() => {
                SheetManager.hide('buy_premium_subscribe');
                setTimeout(() => {
                  this.context.buyPremiumSubscription(false);
                }, 100);

              }} message={('Subscribe to premium and be free of ads and have access to all premium subscription features')} adiacentMessage={`$${this.context.state.appConfig?.premiumPSubPrice}/month auto-renewable`} acceptLabel={`Subscribe`} />
          </View>

        </ActionSheet>
        {
          this.context.state.shareModalVisible ? <Modal
            animationType="fade" style={{
              display: "flex",
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            transparent={true}
            visible={this.context.state.shareModalVisible}
            onRequestClose={() => {
              // Alert.alert("Modal has been closed.");

            }}
          ><TouchableOpacity onPress={() => {
            this.context.setAppProp({ shareModalVisible: false })
          }} style={style.modalOverlay}>
              <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <View style={{
                  width: 350,
                  height: 380,
                  padding: 20,
                  backgroundColor: colors.backgroundColor,
                  borderRadius: colors.borderRadius
                }}>
                  <Share onShare={() => {
                    this.context.setAppProp({ shareModalVisible: false })
                  }}></Share>
                </View>
              </View>
            </TouchableOpacity>
          </Modal> : null
        }

        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="app_review">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert style={{ width: '100%' }} cancelLabel={"Cancel"} onCancel={async () => {
              SheetManager.hide('app_review')
            }} onAccept={async () => {
              await SheetManager.hide('app_review')
              setTimeout(() => {

                this.context.appReview();
              }, 500);
            }} message={"Hey, you! We don't have a perfect app, but we're striving every day to make it better, bug fixing and bringing new and exciting features for you to enjoy. So if overall you enjoy our humble app, please kindly give us a review, it would mean the world for us! Thank you very much!"} acceptLabel={"Rate and Review"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="download_sheet_home">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={Platform.OS === "android" ?
              <Entypo name="google-play" size={24} color={colors.iconColorWhite} /> :
              <FontAwesome5 name="app-store" size={24} color={colors.iconColorWhite} />} style={{ width: '100%' }} onAccept={() => {
                this.context.gotoStore();
                SheetManager.hide('download_sheet_home')
              }} message={"This game can be played only on the mobile app on Google Play and AppStore!"} acceptLabel={"Download"} />
          </View>

        </ActionSheet>




        {this.context.state.appConfig?.privacy && this.context.state.hasShownPolicy === 'false' && Platform.OS !== 'web' ?
          <Modal
            animationType="fade" style={{
              display: "flex",
              bottom: 0,
              top: 0,
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}

            transparent={true}
            visible={this.context.state.appConfig?.privacy && this.context.state.hasShownPolicy === 'false'}

          >
            <View style={[style.fullWidth, style.column, {
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: 0,
            }]}>

              <View style={[{
                height: '100%',
                width: '100%',
                maxWidth: colors.maxWidth,
                padding: 20,
                borderRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                paddingTop: 0,
                backgroundColor: "#fff"
              }]}>
                <ScrollView style={[style.fullWidth, { padding: 20, flex: 1 }]}>
                  <Text style={[style.fullSize, { color: colors.textColor }]}>
                    {this.context.state.appConfig?.privacy?.replace(/<br\/>/gi, '\n') + '\n\n'}
                    {this.context.state.appConfig?.terms?.replace(/<br\/>/gi, '\n')}
                  </Text>
                </ScrollView>
                <PrimaryButton align={'left'} onPress={this.agreePolicy.bind(this)}
                  label={'Accept and Continue'} style={{ width: '100%', marginTop: 10 }} />
              </View>

            </View>
          </Modal> : null}

      </NavigationContainer>
    )
  }
}

export default AppNavigator;

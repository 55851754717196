
import * as React from 'react';
import { ActivityIndicator, Dimensions, Platform, ScrollView, TextInput, StyleSheet, Text, Touchable, TouchableOpacity, View, AsyncStorage } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';

import * as Animatable from 'react-native-animatable';
import Cron from '../components/cron';
import PrimaryButton from '../components/primary-button';
import { Fontisto, Octicons } from '@expo/vector-icons';
import ConfettiCannon from 'react-native-confetti-cannon';
import { SheetManager } from 'react-native-actions-sheet';


interface Props {

}

interface State {
    points?: number,
    uiState: "game" | "correct" | "wrong",
    isInit?: boolean,
    runCron: boolean,
    hints?: number,
    showLetterHintButton?: boolean,
    showLettersHint?: boolean,
    revealLettersIndex?: number[],
    numberLettersReveal?: number,
    myAnswer?: string,
    answer?: string,
    says: any[];
    isBusyHint?: boolean,
    isBusy: boolean
}
class GameScreen extends React.Component<Props, State> {
    isCreatingAnswer: any;
    category: string = "";
    scrollViewRef: any
    cron: any
    static contextType = AppContext;
    declare context: IContext
    constructor(props: any) {
        super(props);
        this.state = {
            runCron: false,
            says: [],
            numberLettersReveal: 0,
            uiState: "game",
            myAnswer: "",
            isBusy: false
        }
    }
    async createAnswer() {
        this.context.logEvent("start_game")
        this.setState({ isBusy: true, isInit: true, hints: 0, showLetterHintButton: true, says: [], points: 0, uiState: "game", showLettersHint: false, numberLettersReveal: 0, revealLettersIndex: [] })
        this.category = (this.context.state.selectedCategory || "Movie")
        //.replace(/s([^s]*)$/, '$1');
        const getRandomAlphabetLetter = () => {
            const alphabet = "ABCDEFGHIJKLMNOPRSTUVW"
            const alphabetArray = alphabet.split("")
            const randomLetter = alphabetArray[Math.floor(Math.random() * alphabetArray.length)];
            return randomLetter
        }
        const getRandomNumberBetweenMinAndMax = (min: number, max: number) => {
            return Math.floor(Math.random() * (max - min + 1) + min);
        }
        console.log('category', this.category)
        //random letters number should be a random number between 4 and 12
        const randomLettersNumber = getRandomNumberBetweenMinAndMax(this.context.state.appConfig.genMinNumChr, this.context.state.appConfig.genMaxNumChr);
        const prompt = this.context.state.appConfig.generatePrompt.replace('{randomCategory}', this.category).replace('{getRandomAlphabetLetter1}', getRandomAlphabetLetter()).replace('{getRandomAlphabetLetter2}', getRandomAlphabetLetter()).replace('{randomLettersNumber}', randomLettersNumber)
        console.log('prompt', prompt)
        const answer = (await this.context.openai(this.context.state.appConfig?.generatePromptSystem, prompt)).replace(`"`, "").replace(`"`, "");
        //await this.context.openai(prompt, "");
        // await this.context.openai(prompt, "");
        //console.log('answer', answer)
        //"Django Unchained"
        // await this.context.openai(prompt, "");
        // "Django Unchained"
        const says = [{ u: 'ai', text: `Guess the "${this.category}" I'm thinking about...` }];
        console.log("answer", answer)
        const numberLettersReveal = Math.max(1, Math.floor(answer.length * 0.2));
        this.setState({ isBusy: false, answer, says, numberLettersReveal }, () => {
            //numberLettersReveal is 20% of the answer length


            this.setState({ runCron: true })
            //this.setState({ uiState: "correct", isBusy: false, points: 10, runCron: false, myAnswer: '', showLettersHint: false })
        })



    }
    getLettersHint() {
        const no = this.state.revealLettersIndex.length;
        if (no >= Math.floor(0.4 * this.state.answer.length)) {
            return;
        }
        this.cron.minusOneMinute();
        const numberLettersReveal = Math.max(1, Math.floor(this.state.answer.length * 0.2));

        //get a random number of letters to reveal taking in consideration the numberLettersReveal
        const revealLettersIndex = this.state.revealLettersIndex || [];
        while (revealLettersIndex.length !== no + numberLettersReveal) {
            const randomIndex = Math.floor(Math.random() * this.state.answer.length);
            if (revealLettersIndex.indexOf(randomIndex) === -1 && this.state.answer[randomIndex] !== ' ') {
                revealLettersIndex.push(randomIndex)
            }

        }
        console.log('revealLettersIndex', revealLettersIndex)
        let showLetterHintButton = true;
        const noNew = revealLettersIndex.length;
        if (noNew >= Math.floor(0.3 * this.state.answer.length)) {
            showLetterHintButton = false;
        }
        this.setState({ showLettersHint: true, showLetterHintButton, numberLettersReveal, revealLettersIndex })
    }
    async getHint() {
        if (this.state.isBusy) {
            return;
        }
        let hints = this.state.hints || 0;
        this.setState({ isBusy: true })
        const prompt = this.context.state.appConfig.hintPrompt.replace('{word}', this.state.answer).replace('{category}', this.category);
        console.log('prompt', prompt)
        const answer = await this.context.openai(prompt, "", 50);
        const says = [...this.state.says];
        says.push({ u: 'ai', text: answer });
        this.setState({ isBusy: false, says, hints: hints + 1 }, () => {
            this.cron.minusOneMinute();
            setTimeout(() => {
                this.scrollViewRef.scrollToEnd({ animated: true })
            }, 10)
        })
    }
    async componentDidMount() {


    }
    tryAgain() {
        this.setState({ runCron: false })
        this.createAnswer();
    }
    async answerQuestion() {
        let says = [...this.state.says];
        says.push({ u: 'me', text: this.state.myAnswer });
        this.setState({ says, isBusy: true }, async () => {
            setTimeout(() => {
                this.scrollViewRef.scrollToEnd({ animated: true })
            }, 10)
            const prompt = this.context.state.appConfig.questionPrompt.replace('{answer}', this.state.answer).replace('{category}', this.category)
            console.log('prompt', prompt)
            const aiAnswer = await this.context.openai(prompt, `${this.state.myAnswer}`);
            says = [...this.state.says];
            if (aiAnswer.toLowerCase().includes('congratulations')) {
                //calculate a number of points received based on the time remaining
                const points = Math.round(this.cron.state.currentTime / 10);
                this.context.updateUsePoints(points);
                this.setState({ uiState: "correct", isBusy: false, points, runCron: false, myAnswer: '', showLettersHint: false })
                this.onEndGame();
            } else {
                says.push({ u: 'ai', text: aiAnswer });
                this.setState({ says, isBusy: false, myAnswer: '' }, () => {
                    //if the messages goes pass the scroll view, make the scrollView component scroll to the very end
                    setTimeout(() => {
                        this.scrollViewRef.scrollToEnd({ animated: true })
                    }, 100)

                });
            }


        });

    }
    onFail() {
        this.setState({ uiState: "wrong", isInit: false })
        this.onEndGame();
    }
    async onEndGame() {
        if (Platform.OS !== 'web') {
            let generatedNumber = parseInt(await AsyncStorage.getItem("generatedNumber") || "0") || 0;
            generatedNumber++;
            if (generatedNumber === 2 && this.context.state.appConfig?.hasReview) {
                SheetManager.show('app_review');
            }
        }

    }
    componentDidUpdate(prevPops: any) {
        if (this.context.state.appConfig?.generatePrompt && !this.isCreatingAnswer) {
            this.createAnswer();
            this.isCreatingAnswer = true;
        }

    }

    getWordIndex(word: string, j: number, i: number) {
        let index = 0;
        for (let k = 0; k < j; k++) {
            index += this.state.answer?.split(" ")[k].length + 1;
        }
        index += i;
        return index;
    }

    render() {

        const busyIndicator = () => {
            if (this.state.isBusy) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            }
        }
        const getUI = () => {
            switch (this.state.uiState) {
                case "game":
                    return <View style={[style.container, { padding: 20, paddingBottom: 5 }]}>
                        {this.state.isInit ? <Cron key={this.state.uiState} ref={ref => { this.cron = ref }} style={{ marginBottom: 10 }} maxTime={this.context.state.appConfig?.maxTimeCron} paused={!this.state.runCron} onEnd={() => {
                            this.onFail();
                        }} /> : null}
                        {this.state.showLettersHint ? <View style={[style.fullWidth, style.row, style.hcenter, { flexWrap: 'wrap' }]}>
                            {this.state.answer?.split(" ").map((word: string, j: number) => {
                                return <View key={j + word} style={[style.row, { marginRight: 10 }]}>
                                    {word.split("").map((letter: string, i: number) => {
                                        return <View key={i} style={[style.row, style.vcenter, style.hcenter, style.vcenter, { borderRadius: 5, borderWidth: letter === ' ' ? 0 : 1, borderColor: colors.secondaryColor, marginBottom: 10, width: 20, height: 20, marginLeft: letter === ' ' ? 1 : 2, backgroundColor: 'transparent' }]}>
                                            <Text style={[{ fontSize: 14, fontWeight: 'bold', color: colors.whiteish, fontFamily: 'Jost' }]}>{this.state.revealLettersIndex?.indexOf(this.getWordIndex(word, j, i)) !== -1 ? letter : ""}</Text>
                                        </View>
                                    })}
                                </View>
                            })
                            }
                        </View> : null}
                        <ScrollView ref={ref => { this.scrollViewRef = ref }} style={{ width: '100%', display: 'flex', marginBottom: 10, marginTop: 10, flex: 1 }} contentContainerStyle={{ width: '100%' }}>
                            {this.state.says?.map((say: any, i: number) => {
                                return <Animatable.View duration={100} animation="slideInUp" key={i} style={[style.row, style.vcenter, { maxWidth: '50%', borderRadius: 20, marginBottom: 10, padding: 10, marginRight: 5, marginLeft: say.u === 'ai' ? '50%' : 5, backgroundColor: say.u === 'ai' ? colors.blueMarin : colors.blueMarin }]}>
                                    <Text style={[{ fontSize: 18, color: colors.whiteish, fontFamily: 'Jost' }]}>{say.text}</Text>
                                </Animatable.View>
                            }
                            )}
                        </ScrollView>
                        {/*  <PrimaryButton isSecondary={true} isBusy={this.state.isBusyHint} onPress={this.getHint.bind(this)} style={{ marginBottom: 20 }} icon={<Fontisto name="arrow-swap" size={18} style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }} color={colors.whiteish} />} label={`1 minute ${} 1 hint`}></PrimaryButton> */}
                        <View style={[style.fullWidth, style.row]}>
                            {this.state.hints < 1 ? <TouchableOpacity style={{ marginBottom: 20, marginRight: 10 }} onPress={this.getHint.bind(this)}>
                                <View style={[style.row, style.vcenter, { padding: 5, paddingLeft: 20, paddingRight: 20, borderRadius: colors.borderRadius, backgroundColor: colors.blueMarin }]}>
                                    <Text style={[{ fontSize: 16, marginRight: 10, color: colors.whiteish, fontFamily: 'Jost' }]}>1m</Text>
                                    <Fontisto name="arrow-swap" size={11} style={{ marginTop: 3, marginRight: 10 }} color={colors.whiteish} />
                                    <Text style={[{ fontSize: 16, color: colors.whiteish, fontFamily: 'Jost' }]}>1 hint</Text>
                                </View>
                            </TouchableOpacity> : null}
                            {this.state.showLetterHintButton ? <TouchableOpacity style={{ marginBottom: 20 }} onPress={this.getLettersHint.bind(this)}>
                                <View style={[style.row, style.vcenter, { padding: 5, paddingLeft: 20, paddingRight: 20, borderRadius: colors.borderRadius, backgroundColor: colors.blueMarin }]}>
                                    <Text style={[{ fontSize: 16, marginRight: 10, color: colors.whiteish, fontFamily: 'Jost' }]}>1m</Text>
                                    <Fontisto name="arrow-swap" size={11} style={{ marginTop: 3, marginRight: 10 }} color={colors.whiteish} />
                                    <Text style={[{ fontSize: 16, color: colors.whiteish, fontFamily: 'Jost' }]}>{this.state.numberLettersReveal} letters</Text>
                                </View>
                            </TouchableOpacity> : null}
                        </View>
                        <TextInput enabled={!this.state.isBusy} autoCapitalize={'sentences'} value={this.state.myAnswer} autoCorrect={true} blurOnSubmit={true} maxLength={30}
                            enablesReturnKeyAutomatically={true} onSubmitEditing={() => {
                                this.answerQuestion();
                            }} placeholder='Question...' multiline={false} numberOfLines={1} onChangeText={(text: string) => {
                                this.setState({ myAnswer: text })
                            }} style={[style.fullWidth, style.textInput, {
                                padding: 15,
                                textAlignVertical: 'center',
                                fontFamily: 'Jost',
                                marginBottom: 16,
                                color: colors.neutralBlue
                            }]}></TextInput>

                    </View>
                    break;
                case "correct":
                    return <View style={[style.container, style.hcenter, style.vcenter, { padding: 20, paddingBottom: 20 }]}>
                        <ConfettiCannon count={200} fadeOut={true} explosionSpeed={1000} fallSpeed={2000} origin={{ x: Dimensions.get('screen').width / 2, y: 0 }} />
                        <View style={[style.fullWidth, style.hcenter, style.vcenter, { flex: 1 }]}>
                            <Animatable.Text direction="alternate" duration={300} animation="fadeOut" iterationCount="infinite" style={[{ fontSize: 30, color: colors.whiteish, fontFamily: 'Jost' }]}>CORRECT!!!</Animatable.Text>
                            <Text style={[{ fontSize: 20, marginTop: 10, color: colors.whiteish, fontFamily: 'Jost' }]}>Received {this.state.points} points</Text>
                        </View>
                        <PrimaryButton onPress={() => { this.tryAgain() }} style={{ width: '100%' }} label="Try Again"></PrimaryButton>
                    </View>
                    break;
                case "wrong":
                    return <View style={[style.container, style.hcenter, style.vcenter, { padding: 20, paddingBottom: 20 }]}>
                        <View style={[style.fullWidth, style.hcenter, style.vcenter, { flex: 1 }]}>
                            <Animatable.Text direction="alternate" duration={300} animation="fadeOut" iterationCount="infinite" style={[{ fontSize: 30, color: colors.whiteish, fontFamily: 'Jost' }]}>FAILED</Animatable.Text>
                            <Text style={[{ fontSize: 20, marginTop: 20, maxWidth: '100%', color: colors.whiteish, fontFamily: 'Jost' }]}>Correct answer: {this.state.answer}</Text>
                        </View>
                        <PrimaryButton onPress={() => { this.tryAgain() }} style={{ width: '100%' }} label="Try Again"></PrimaryButton>
                    </View>
                    break;
            }
        }


        return (

            <View style={style.page}>
                {getUI()}
                {busyIndicator()}
            </View >

        )
    };

}

export default (withTranslation()(GameScreen));

const styles = StyleSheet.create({
    input: {
        padding: 5,
        height: 35,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: colors.textColor,
        color: colors.textColor
    },
    screenItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        backgroundColor: colors.textColor
    }
});
import { FontAwesome } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView
} from 'react-native';

import colors from '../constants/colors';
import style from '../constants/style';

//@ts-ignore
import { FormattedText } from 'react-native-formatted-text';
import { AppContext, IContext } from "../store/app-provider";

interface Props {

}

interface State {

}

class PrivacyScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

  }

  render() {

    return (

      <View style={style.page}>
        <View style={[style.container]}>
          <ScrollView style={[style.fullSize, { padding: 20 }]}>
            <Text style={[style.fullSize, { color: colors.whiteish }]}>
              {this.context.state.appConfig?.privacy?.replace(/<br\/>/gi, '\n')}
            </Text>
          </ScrollView>
        </View>
      </View>

    )
  };

}

export default PrivacyScreen;

const styles = StyleSheet.create({});

import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import * as Animatable from 'react-native-animatable';
interface State {
    currentTime: number
}
interface Props {
    maxTime: number,
    onEnd?: () => void,
    paused?: boolean,
    style?: ViewStyle
}
class Cron extends React.Component<Props, State>  {
    static contextType = AppContext;
    declare context: IContext
    isCreatingAnswer: any

    constructor(props: any) {
        super(props);
        console.log('props', props)
        this.state = { currentTime: this.props.maxTime }

    }
    minusOneMinute() {
        this.setState({ currentTime: this.state.currentTime - 60 })
    }
    getMinutesAndSecondsStringFormSeconds(seconds: number) {
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.paused !== this.props.paused) {
            if (this.props.paused) {
                clearInterval(this.isCreatingAnswer)
            } else {
                this.isCreatingAnswer = setInterval(() => {
                    if (this.state.currentTime > 0) {
                        this.setState({ currentTime: this.state.currentTime - 1 })
                    } else {
                        clearInterval(this.isCreatingAnswer);
                        this.props.onEnd && this.props.onEnd();
                    }
                }, 1000)
            }
        }
    }

    componentDidMount() {

    }
    componentWillUnmount() {
        if (this.isCreatingAnswer) clearInterval(this.isCreatingAnswer)
    }


    render() {

        return (
            <Animatable.View animation="pulse" iterationCount="infinite" style={[style.row, this.props.style, style.hcenter, style.vcenter, { borderRadius: colors.borderRadius, width: 80, height: 40, backgroundColor: colors.primaryColor }]}>
                <Text style={[{ fontSize: 18, color: colors.whiteish, fontFamily: 'Jost' }]}>{this.getMinutesAndSecondsStringFormSeconds(this.state.currentTime)}</Text>
            </Animatable.View>
        )
    };

}


const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});



export default Cron;

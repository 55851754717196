/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserDevice = /* GraphQL */ `
  query GetUserDevice($id: ID!) {
    getUserDevice(id: $id) {
      id
      type
      alias
      points
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserDevices = /* GraphQL */ `
  query ListUserDevices(
    $filter: ModelUserDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        alias
        points
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTopByPoints = /* GraphQL */ `
  query GetTopByPoints(
    $type: String!
    $points: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTopByPoints(
      type: $type
      points: $points
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        alias
        points
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import { AntDesign, Entypo, FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  FlatList,
  Platform,
  RefreshControl,
  AsyncStorage, Image, Modal, BackHandler, ScrollView, TextInput, ActivityIndicator
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from './../services/navigation-service';
import { AppContext, IContext } from '../store/app-provider';
import { SheetManager } from "react-native-actions-sheet";
import ItemsList from "../components/items-list";
import { API } from "aws-amplify";
import env from "../constants/env";
import PrimaryButton from '../components/primary-button';


interface Props {

}

interface State {
  type: string

}

class HomeScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  itemList: any

  constructor(props: any) {
    super(props);
    this.state = { type: 'public' }
  }



  async componentDidMount() {
    navigationService.props.setHome = true;
  }

  componentDidUpdate(prevPops: any) {

  }
  gotoGame = (category: any) => {
    console.log('category', category)
    if (Platform.OS === "web" && !this.context.state.isMock) {
      SheetManager.show('download_sheet_home');
      return;
    }
    this.context.setAppProp({ selectedCategory: category.single }, () => {
      this.context.setScreen('Game', true)
    })
  }


  setPrivate(e: any) {
    if (e.type !== this.state.type) {
      this.setState({ type: e.type }, () => {
        this.itemList.refresh();
      })
    }

  }


  render() {
    const busyIndicator = () => {
      if (this.context.state.isBusy) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
      }
    }

    return (
      <View style={style.page}>
        <View style={[style.container, { paddingBottom: 10 }]}>
          <ScrollView contentContainerStyle={{ display: 'flex', marginBottom: 10, marginTop: 10, flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', paddingBottom: 10 }} style={{ flex: 1, width: '100%' }}>
            {this.context.state.appConfig?.categories?.map((category: any, i: number) => {
              return <TouchableOpacity onPress={this.gotoGame.bind(this, category)} style={{ width: 160, height: 190, margin: 5 }} key={i}>
                <View style={[style.column, style.vcenter, { width: '100%', height: '100%', backgroundColor: colors.secondaryColor, borderRadius: 30, padding: 10 }]}>
                  <Image style={{ width: '100%', flex: 1, borderRadius: 30 }} source={{ uri: category.pic }} />
                  <Text style={{ fontFamily: 'Jost', fontWeight: 'bold', color: colors.textColor, fontSize: 18, marginTop: 10, width: '100%', padding: 3, textAlign: 'center' }}>{category.label}</Text>
                </View>
              </TouchableOpacity>
            })}
          </ScrollView>
          {/* <PrimaryButton style={{ width: '100%' }} label="Start"></PrimaryButton> */}
          {busyIndicator()}
        </View>

      </View >
    )

  }
}

export default HomeScreen



import { Platform } from "react-native";
import { Feather, MaterialIcons } from "@expo/vector-icons";


let _navigator: any;
let props: any = {
  gaTracker: null,
  setHome: null,
}

function setTopLevelNavigator(navigatorRef: any) {
  _navigator = navigatorRef;
}

function navigate(name: any, params: any, key?: string) {

  if (_navigator?.isReady()) {
    if (!key) {
      _navigator?.navigate(name, params);
    } else {
      _navigator?.navigate(name, params);
    }
    //_navigator?.push(name, params);
  }
}

function pop() {
  if (_navigator?.isReady()) {
    _navigator.goBack();
  }
}

function resetRoutes() {
  _navigator.resetRoot({
    index: 0,
    routes: [{ name: 'Home' }],
  });
}

function getCurrentScreen() {
  return _navigator?.getCurrentRoute()?.name;
}

function getNavigation() {
  return _navigator;
}

function registerGAEvent(eventName: string) {
  /*  if (Platform.OS === 'web') {
       ReactGA.event({
           category: 'Web',
           action: `Web ${eventName}`
       });
   } else {
       props.gaTracker.trackEvent("Mobile", `Mobile ${eventName}`);
   } */
}

function navigateToTab(name: any, screen: any, params: any) {

  if (_navigator?.isReady()) {
    _navigator?.navigate(name, {
      screen: screen,
      params: params,
    })
  }
}

export default {
  registerGAEvent,
  resetRoutes,
  props,
  navigate, navigateToTab, getCurrentScreen, getNavigation,
  pop,
  setTopLevelNavigator,
};

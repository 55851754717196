import { FontAwesome } from '@expo/vector-icons';
import * as React from 'react';
import {
    StyleSheet,
    Text,
    View,
    ScrollView,
    TextInput,
    Platform
} from 'react-native';
import * as queries from "./../graphql/queries";
import colors from '../constants/colors';
import style from '../constants/style';

//@ts-ignore
import { FormattedText } from 'react-native-formatted-text';
import { AppContext, IContext } from "../store/app-provider";
import PrimaryButton from '../components/primary-button';
import { UserDevice } from '../models';
import { API } from 'aws-amplify';

interface Props {

}

interface State {
    isBusy?: boolean
    newAlias?: string
    userDevices?: UserDevice[]
}

class StatsScreen extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext
    isInit = false;
    constructor(props: any) {
        super(props);
        this.state = {

        }

    }

    async componentDidMount() {
        const userDevices = (await API.graphql({
            query: queries.getTopByPoints,
            variables: { type: "gamer", limit: 10, sortDirection: "DESC" }
        }) as any).data.getTopByPoints.items as UserDevice[]
        console.log(userDevices)
        this.setState({ userDevices })

    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.context.state.userDevice && !this.isInit) {
            this.setState({ newAlias: this.context.state.userDevice.alias })
            console.log("kakakaya")
            this.isInit = true;
        }
    }

    render() {

        return (

            <View style={style.page}>
                <View style={[style.container, { padding: 10 }]}>
                    {Platform.OS !== 'web' || this.context.state.isMock ? <View style={[style.fullWidth]}>
                        <Text style={{ fontSize: 18, color: colors.whiteish, marginRight: 10, width: '100%', marginLeft: 5, fontWeight: 'bold', fontFamily: 'Jost' }}>Nickname</Text>
                        <View style={[style.fullWidth, style.row, style.vcenter, { marginTop: 10, justifyContent: 'flex-start' }]}>

                            <TextInput value={this.state.newAlias} maxLength={30}
                                multiline={false} numberOfLines={1} onChangeText={(text: string) => {
                                    this.setState({ newAlias: text })
                                }} style={[style.textInput, {
                                    flex: 1,
                                    marginRight: 10,
                                    textAlignVertical: 'center',
                                    fontFamily: 'Jost',
                                    color: colors.neutralBlue
                                }]}></TextInput>
                            <PrimaryButton isBusy={this.state.isBusy} onPress={async () => {
                                this.setState({ isBusy: true })
                                await this.context.updateUserAlias(this.state.newAlias)
                                this.setState({ isBusy: false })
                            }} style={{ flex: 1 }} label="Update"></PrimaryButton>
                        </View>

                        <Text style={{ fontSize: 18, color: colors.whiteish, marginTop: 10, width: '100%', marginBottom: 10, marginLeft: 5, fontWeight: 'bold', fontFamily: 'Jost' }}>My Points: {this.context.state.userDevice?.points}</Text>
                    </View> : null
                    }
                    <Text style={{ fontSize: 18, color: colors.whiteish, marginTop: 10, width: '100%', marginBottom: 10, marginLeft: 10, fontWeight: 'bold', fontFamily: 'Jost' }}>Top users:</Text>
                    <ScrollView style={[style.fullWidth, { padding: 0, flex: 1, marginTop: 10 }]}>
                        {this.state.userDevices && this.state.userDevices.map((userDevice, index) => {
                            return (
                                <View key={index} style={[style.row, style.vcenter, style.fullWidth, { marginBottom: 10 }]}>
                                    <Text style={{ fontSize: 18, flex: 1, color: colors.whiteish, marginRight: 0, width: '100%', marginLeft: 5, fontWeight: 'bold', fontFamily: 'Jost' }}>{index + 1}. {userDevice.alias}</Text>
                                    <Text style={{ fontSize: 18, color: colors.whiteish, marginRight: 10, marginLeft: 10, fontWeight: 'bold', fontFamily: 'Jost' }}>{userDevice.points}</Text>
                                </View>
                            )
                        })}
                    </ScrollView>
                </View>
            </View>

        )
    };

}

export default StatsScreen;

const styles = StyleSheet.create({});

import React, { Component } from "react";
import navigationService from "../services/navigation-service";
import { Alert, AppState, AsyncStorage, BackHandler, Dimensions, Linking, Platform, Share } from "react-native";
import { InAppBrowser } from "react-native-inappbrowser-reborn";
import { Amplify, API, Auth, graphqlOperation } from "aws-amplify";
//import config from "./../aws-exports";


const CryptoJS = require('crypto-js');
import * as mutations from "./../graphql/mutations";
import * as queries from "./../graphql/queries";
import {
  createUserDevice
} from "./../graphql/mutations";
import DeviceInfo from "react-native-device-info";
import { SheetManager } from "react-native-actions-sheet";
import InAppReview from 'react-native-in-app-review';
import { Storage } from "aws-amplify";
import colors from "../constants/colors";
import analytics from "@react-native-firebase/analytics";
import ReactGA from "react-ga4";
///@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { UserDevice } from "../models";
export interface IAppState {
  isMock: boolean;
  userDevice?: UserDevice
  correctAnswer?: string;
  selectedCategory?: string;
  requestNonPersonalizedAdsOnly: boolean;
  appMenuOpened?: boolean;
  appMenuOpenedBack?: boolean;
  packageName?: any;
  checkedStore?: boolean
  adData?: any
  isAdPlaying?: boolean,
  receivedReward?: boolean
  isAdmin?: boolean;
  os?: string
  shareUrl?: string,
  hasShownPolicy: string;
  routeParams: any,
  shareModalVisible?: boolean,
  isBusy?: boolean
  appConfig?: any
  screen?: any
  isPremium: boolean;
  isPremiumSubscriber: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isPortrait: boolean;
}

export interface IProps {
  Toast: any
}

export interface IContext {
  state: IAppState,
  hideMenu: () => void,
  signOut: () => void,
  updateUserAlias: (nickname: string) => void,
  updateUsePoints: (points: number) => void,
  shareMe: (url: string) => void,
  gotoStore: () => void,
  logEvent: (name: string) => void
  buyPremium: () => void,
  openai: (sys: string, p: string, length?: number) => string,
  appReview: () => void,
  buyPremiumSubscription: (isYearly?: boolean) => void,
  restorePurchase: () => void,
  playRewardedAdAndGenerate: (callback?: any) => void,
  createAd: (addType: string, callback?: any, onClose?: any) => void,
  getListItems: (stateKey: any, fn: any, nextTokenName: string, refresh: boolean) => void,
  setScreen: (screen: string, navigate?: boolean, params?: any, callback?: any) => void
  setAppProp: (obj: any, callback?: any) => void,
  showToast: (message: string, type: 'error' | 'success') => void,
}

const initialState: any = {
  requestNonPersonalizedAdsOnly: false,
  appConfig: {},
  isMock: false,
  isBusy: false
}

export const AppContext = React.createContext<IContext>(initialState);
export const AppContextProvider = AppContext.Provider

export class AppProvider extends Component<IProps, any> {
  state = initialState
  firstScreen: any

  doNotPlayAd: boolean = false;
  tm?: any
  public currentPlayTime: number = 0;
  private canPlay: boolean = false;
  private soundtrack: any;
  private audio: any;

  constructor(props: IProps) {
    super(props);

  }

  gotoStore() {
    if (this.state.os === 'ios') {
      window.open(this.state.appConfig.iOSApp)

    } else {
      window.open(this.state.appConfig.androidApp)
    }
  }




  async componentDidMount() {

    if (Platform.OS === 'web') {
      window.addEventListener("resize", this.checkOrientation.bind(this));
      this.checkOrientation();
    } else {
      if (Platform.OS === 'android') {
        this.listenBackButton();
      }
      Dimensions.addEventListener('change', () => {
        this.checkOrientationMobile();
      });
      this.checkOrientationMobile();
    }
    this.getAppConfig();
  }

  isPortrait = () => {
    const dim = Dimensions.get('window');
    return dim.height >= dim.width;
  };
  getAd = (addType: string, callback?: any) => {
    let ad;
    console.log("getAd", addType, this.state.adData[addType])
    switch (addType) {
      case "re":
        ad = navigationService.props.RewardedAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "in":
        ad = navigationService.props.InterstitialAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "inre":
        ad = navigationService.props.RewardedInterstitialAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "op":
        ad = navigationService.props.AppOpenAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
    }
    return ad;
  }
  createAd = (addType: string, callback?: any, onClose?: any) => {
    let ad: any;
    return;
    if (Platform.OS === "web" || this.state.isPremium) return;
    if (addType === 'open') {
      ad = this.getAd(this.state.adData['openType']);
    } else {
      ad = this.getAd(addType);
    }
    //console.log("createAd", addType, ad)

    try {
      ad.addAdEventListener('rewarded_loaded', () => {
        ad.show();
        this.doNotPlayAd = true;
        this.setState({ isAdPlaying: true, receivedReward: false })
        if (callback) callback(ad);
      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('loaded', () => {
        ad.show();
        this.doNotPlayAd = true;
        this.setState({ isAdPlaying: true });
        if (callback) callback(ad);
      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('closed', () => {

        this.setPlayAd(false)
        this.setState({ isAdPlaying: false })
        if (onClose && addType === "in") {
          onClose()
        };

      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('error', (e: any) => {
        console.log("error", e)
        this.setPlayAd(false)
        this.setState({ isAdPlaying: false })
        if (onClose) onClose();

      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('rewarded_earned_reward', () => {
        this.setPlayAd(false)
        this.setState({ isAdPlaying: false, receivedReward: true }, () => {
          if (onClose) onClose();
        })

      });
    } catch (e) {
    }
    ad.load();
    return ad;
  }
  listenBackButton = () => {
    const me = this;
    const backHandler = BackHandler.addEventListener('hardwareBackPress', function () {
      console.log("back pressed", me.state.screen)
      if (me.state.appMenuOpened) {
        me.hideMenu();
        backHandler.remove();
        me.listenBackButton();
        return true;
      } else {
        if ((me.state.screen === 'Home' || !me.state.screen)) {
          //throw {};
          backHandler.remove();
          me.listenBackButton();
          BackHandler.exitApp();
          return true;
        }
      }
      if (!navigationService.props.setHome && !me.state.viewPost && !me.state.appMenuOpened) {
        //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen('Home', {}, false));
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
        backHandler.remove();
        me.listenBackButton();
        return true;
      }



      return false;
    });
  }
  playRewardedAdAndGenerate = (callback?: any) => {
    this.createAd('re', (ad: any) => {
      ad.show();
    }, () => {
      if (this.state.receivedReward) {
        if (!callback) {
          //this.getMagicText(true);
        } else {
          callback();
        }

      } else {
        this.showToast("You did not get a reward, please wait until the ad finishes playing and the reward is received!", "error")
      }
    })
  }

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }



  getListItems = async (stateKey: any, fn: any, nextTokenName: string, refresh: boolean) => {
    const obj = { ...this.state[stateKey] }
    //console.log("nextTokenName", obj.nextToken)
    if (!refresh && !obj.nextToken) return;
    //obj.loading = !refresh || !obj.items.length;

    obj.loading = !obj.items.length;
    obj.refreshing = refresh && obj.items.length !== 0;
    if (refresh) {
      obj.items = [];
    }
    console.log("START !!!")
    this.setState({ [stateKey]: { ...obj } });
    let items = refresh ? await fn() : await fn(obj.nextToken);
    console.log("FETCH !!!", items.items.length)
    //console.log("getListItems", JSON.stringify(items));
    obj.items = refresh ? items.items : obj.items.concat(items.items)
    //make sure obj.items contains unique objects with videoId
    obj.items = obj.items.filter((item: any) => {
      const postReports = item.reports?.length || 0;
      const userReports = item.user?.reports?.length || 0;
      const isBlockedByMe = item.user?.reports?.includes(this.state.user?.id);
      return postReports < 10 && userReports < 10 && !isBlockedByMe;
    })
    obj.nextToken = items[nextTokenName]
    obj.loading = false;
    obj.refreshing = false;
    this.setState({ [stateKey]: obj });
  }

  checkOrientationMobile() {
    const orientation = this.isPortrait() ? 'portrait' : 'landscape'
    this.setState({
      orientation,
      isMobile: true,
      isTablet: DeviceInfo.isTablet(),
      isPortrait: orientation === "portrait"
    });
  }



  checkOrientation() {
    let os;
    if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
      os = "android";
    } else if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      os = "ios";
      console.log("IOS")
    } else {
      os = "kaka";
    }
    const orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
    this.setState({
      orientation,
      os,
      isMobile: this.detectMob(),
      isTablet: window.innerWidth > 1024,
      isPortrait: orientation === 'portrait'
    });

  }
  shareMe = async (url: string) => {
    console.log("shareMe", url)
    this.setPlayAd(true);
    await Share.share({
      title: "WordzGenie",
      message: url,
      url: url
    });
    this.setPlayAd(false);

  }

  openaiRequest = async (body: any) => {
    return new Promise((resolve, reject) => {
      const url = 'https://api.openai.com/v1/chat/completions';
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': this.state.appConfig.openai.key
      };

      fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(body => {
          resolve(body);
        })
        .catch(error => {
          throw new Error(error);
        });
    });

  }
  openai = async (system: string, prompt: string, length?: number) => {
    let response: any;
    try {
      response = await this.openaiRequest({
        model: this.state.appConfig.openai.model,
        messages: [
          {
            role: 'system',
            content: system
          },
          {
            role: 'user',
            content: prompt
          }],
        temperature: this.state.appConfig.openai.temperature,
        max_tokens: length || this.state.appConfig.openai.max_tokens,
        top_p: this.state.appConfig.openai.top_p,
        stream: false,
        presence_penalty: this.state.appConfig.openai.presence_penalty,
        frequency_penalty: this.state.appConfig.openai.frequency_penalty
      })
      return response.choices[0].message.content;
    }
    catch (e) {
      this.showToast("Error, please try again later!", "error");
      return ""
    }
  }


  getAppConfig = async () => {
    let appConfig = (await API.get('restapi', '/rest/config', {})).config;
    //console.log("appConfig", appConfig)
    const bytes = CryptoJS.AES.decrypt(appConfig, "kXp2s5v8y/B?E(G+KbPeShVmYq3t6w9z369");
    appConfig = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    appConfig.allowSubscription = true;
    //appConfig.generatePrompt = `Generate a random and popular {randomCategory} in double quotes that starts with the letter {getRandomAlphabetLetter1} and ends with the letter {getRandomAlphabetLetter2}`;
    //appConfig["allowSubscription" + Platform.OS.toString()];
    let adData = Platform.OS !== 'web' ? appConfig.adds[Platform.OS.toString()] : {};

    let hasShownPolicy = true
    /*  Platform.OS !== "web" ? (await AsyncStorage.getItem("hasShownPolicy2")) : await localStorage.getItem("hasShownPolicy2");
     hasShownPolicy = hasShownPolicy ? 'true' : 'false'; */

    this.setState({ appConfig, adData }, () => {
      this.initStore();
    });
    if (this.state.screen !== "View") {
      setTimeout(() => {
        this.setState({ hasShownPolicy });
      }, 500);
    }

    //console.log("appConfi", appConfig)
    if (Platform.OS !== 'web') {
      let version = DeviceInfo.getVersion();
      //construct the build number based on version string like for example 0.1.0 will be 10
      let iosBuildNumber = parseInt(version.split(".").join(""));
      let buildNumber = Platform.OS === 'android' ? parseFloat(DeviceInfo.getBuildNumber()) : iosBuildNumber;
      const upVersion = appConfig.versions[Platform.OS];
      if (upVersion > buildNumber) {
        this.showUpdateAlert(appConfig);
      }
      console.log("upVersion", buildNumber, upVersion)
    }
    if (Platform.OS !== 'web' || this.state.isMock) {
      this.setUser();
    }
  }
  setUser = async () => {
    const deviceId = await DeviceInfo.getDeviceId();
    console.log("deviceId", deviceId)
    const userDeviceId = Platform.OS !== 'web' ? deviceId : "sdfdrthfyujuk";
    let userDevice = (await API.graphql({ query: queries.getUserDevice, variables: { id: userDeviceId } }) as any).data.getUserDevice;
    console.log("userDevice", userDevice)
    if (!userDevice) {
      //alias is a 8 random chars string alphabet only
      const alias = uuidv4().replace(/[^a-zA-Z]/g, '').substring(0, 8);
      userDevice = {
        id: userDeviceId,
        type: "gamer",
        alias,
        points: 0
      }
      userDevice = (await API.graphql(graphqlOperation(createUserDevice, { input: userDevice })) as any).data.createUserDevice;
      console.log("userDevice", userDevice)

    }
    this.setState({ userDevice })
  }
  updateUserAlias = async (alias: string) => {
    //this.setState({ isBusy: true })
    if (!alias) {
      this.showToast("Please enter a nickname!", "error");
      return;
    }
    if (alias.length > 30) {
      this.showToast("Please enter a nickname with maximum 30 characters!", "error");
      return;
    }
    let userDevice: any = { id: this.state.userDevice.id };
    userDevice.alias = alias;
    userDevice = (await API.graphql(graphqlOperation(mutations.updateUserDevice, { input: userDevice })) as any).data.updateUserDevice;
    console.log("userDevice", userDevice)
    this.setState({ userDevice })
    this.showToast("Nickname updated!", "success");
    //this.setState({ isBusy: false })
  }
  updateUsePoints = async (points: number) => {
    let userDevice: any = { id: this.state.userDevice.id };
    userDevice.points = (this.state.userDevice.points || 0) + points;
    userDevice = (await API.graphql(graphqlOperation(mutations.updateUserDevice, { input: userDevice })) as any).data.updateUserDevice;
    console.log("userDevice", userDevice)
    this.setState({ userDevice })
  }
  initStore = async () => {
    if (Platform.OS === "web") return;
    return;
    let isPremium = false;
    const prodSubId = Platform.OS === "android" ? "com.wordzgenie.monthly" : "com.wordzgenie.monthly";
    const predYearlySubId = Platform.OS === "android" ? "com.wordzgenie.yearly" : "com.wordzgenie.yearly";

    await navigationService.props.adapty.activate('public_live_XrSQI2uC.jvv4HficOrt8Y37NPsfB');
    try {
      const info = await navigationService.props.adapty.getProfile();
      console.log("subscription info", JSON.stringify(info))
      if ((info?.subscriptions?.[prodSubId]?.isActive && !info?.subscriptions?.[prodSubId]?.isRefund) || (info?.subscriptions?.[predYearlySubId]?.isActive && !info?.subscriptions?.[predYearlySubId]?.isRefund)) {
        isPremium = true;
        console.log("premium")
      }
    } catch (error: any) {
      console.log("error", error);
      //toast.show(error.localizedDescription, { type: 'error' })
      this.showToast(error.localizedDescription, "error")
    }

    /*   isPremium = false;
      isPremiumSubscriber = false; */
    this.setState({ checkedStore: true, isPremium })

    if (!isPremium) {
      const {
        consentStatus,
        isConsentFormAvailable,
        isRequestLocationInEeaOrUnknown,
      } = await navigationService.props.UMP.requestConsentInfoUpdate()
      if (
        isRequestLocationInEeaOrUnknown &&
        isConsentFormAvailable &&
        consentStatus === (navigationService.props.UMP.CONSENT_STATUS.REQUIRED)
      ) {
        const { consentStatus } = await navigationService.props.UMP.showConsentForm()
        AsyncStorage.setItem("consentStatus", consentStatus.toString());

      }
      const consentStatus2 = await AsyncStorage.getItem("consentStatus");
      console.log("consentStatus2", consentStatus2)
      if (consentStatus2 !== "3" && consentStatus2) {
        this.setState({ requestNonPersonalizedAdsOnly: true })
      }
      /*  if (generatedLyricsNumber >= 2) {
         this.playIntroAd();
         if (this.state.adData.hasIntroAd2) {
           AppState.addEventListener("change", (nextAppState: any) => {
             if (nextAppState === "active") {
               this.playIntroAd();
             }
 
           });
 
         }
       } */

    }


  }

  playIntroAd = async () => {
    if (this.state.isPremium || this.doNotPlayAd) return;
    this.doNotPlayAd = true;
    setTimeout(() => {
      this.createAd('op', (ad: any) => {
        setTimeout(() => {
          this.doNotPlayAd = false;
        }, 60 * 1000)
      }, () => {

      })
    }, 1000);
  }

  setPlayAd(doNot?: boolean) {
    if (doNot) {
      this.doNotPlayAd = true;
    } else {
      setTimeout(() => {
        this.doNotPlayAd = false;
      }, 3000);
    }

  }

  buyPremium = async () => {
    /* console.log("buyPremium")
    try {


      this.setPlayAd(true);
      const packName = Platform.OS === "android" ? "com.lyritunes.premium" : "com.lyritunes.premium2";

      const { products } = await adapty.paywalls.getPaywalls();
      console.log("products", products)
      const adaptyProduct = products.find((p: any) => p.vendorProductId === packName) || products[0];
      const {
        receipt,
        purchaserInfo,
        product
      }: any = await adapty.purchases.makePurchase(adaptyProduct);
      const info = await adapty.purchases.getInfo() || null;
      if (info?.nonSubscriptions?.[packName] && !info?.nonSubscriptions?.[packName]?.isRefund) {
        console.log("receipt", receipt);
        console.log("purchaserInfo", JSON.stringify(purchaserInfo));
        console.log("product", JSON.stringify(product));
        this.showToast("Success, you are now a LyriTunes Premium user!", "success");
        this.setState({ isPremium: true })
        this.logEvent("buy_premium");
        this.setPlayAd(false);
        this.sendBuyMail(false);
        console.log("premium")
      }



      //toast.show("Success, you are now a YouVi Premium user!", { type: 'success' })



    } catch (error: any) {
      console.log("error", error);
      //toast.show(error.localizedDescription, { type: 'error' })
      this.showToast(error.localizedDescription || error.message, "error")

      //this.showToast(error.localizedDescription, "error");
    } */

  };
  buyPremiumSubscription = async (isYearly?: boolean) => {
    console.log("buyPremium")
    try {


      this.setPlayAd(true);
      let packName = "";
      if (!isYearly) {
        packName = Platform.OS === "android" ? "com.wordzgenie.monthly" : "com.wordzgenie.monthly";

      } else {
        packName = Platform.OS === "android" ? "com.wordzgenie.yearly" : "com.wordzgenie.yearly";
      }

      const paywall = await navigationService.props.adapty.getPaywall('PlacementID');
      const products = await navigationService.props.adapty.getPaywallProducts(paywall);
      console.log("products", products)
      const adaptyProduct = products.find((p: any) => p.vendorProductId === packName) || products[0];
      const {
        receipt,
        purchaserInfo,
        product
      }: any = await navigationService.props.adapty.makePurchase(adaptyProduct);
      console.log("receipt", receipt);
      console.log("purchaserInfo", JSON.stringify(purchaserInfo));
      console.log("product", JSON.stringify(product));
      const info = await navigationService.props.adapty.getProfile();

      console.log("info", JSON.stringify(info))
      if (info?.subscriptions?.[packName]?.isActive && !info?.subscriptions?.[packName]?.isRefund) {
        this.showToast("Success, you are now a WordzGenie Premium subscriber!", "success");
        this.setState({ isPremium: true })
        this.logEvent(`buy_${isYearly ? 'yearly' : 'monthly'}_premium_subscription`);
        this.setPlayAd(false);
        this.sendBuyMail(true, isYearly);
      }

    } catch (error: any) {
      console.log("error", error);
      //toast.show(error.localizedDescription, { type: 'error' })
      this.showToast(error.localizedDescription || error.message, "error")

      //this.showToast(error.localizedDescription, "error");
    }

  };
  sendBuyMail = async (isSub: boolean, isYearly?: boolean) => {
    try {
      await API.post('lyritunesrest', `/rest/send-email/`, {
        body: {
          emailFrom: 'no-reply@lyritunes.com',
          emailTo: 'cristian.anderes.radu@gmail.com',
          message: `Sucessfully bought premium`,
          subject: `LyriTunes Buy ${isSub ? (isYearly ? 'Yearly Sub' : 'Monthly Sub') : 'Premium'} - ${Platform.OS.toString()}!`
        }
      })
    } catch (e: any) {
      console.log("error", e)
    }
  }
  restorePurchase = async () => {
    console.log("restore")
    const prodSubId = Platform.OS === "android" ? "com.wordzgenie.monthly" : "com.wordzgenie.monthly";
    const predYearlySubId = Platform.OS === "android" ? "com.wordzgenie.yearly" : "com.wordzgenie.yearly";
    try {
      const { products } = await navigationService.props.adapty.paywalls.getPaywalls();
      console.log("products", products)
      const {
        purchaserInfo,
        receipt,
        googleValidationResults,
      } = await navigationService.props.adapty.purchases.restore()
      const info = await navigationService.props.adapty.purchases.getInfo() || null;
      if ((info?.subscriptions?.[prodSubId]?.isActive && !info?.subscriptions?.[prodSubId]?.isRefund) || (info?.subscriptions?.[predYearlySubId]?.isActive && !info?.subscriptions?.[predYearlySubId]?.isRefund)) {
        this.setState({ isPremium: true })
        this.showToast("Success, you are now a WordzGenie Premium subscriber!", "success")
        console.log("premium sub")
        return;

      }
      console.log("info", JSON.stringify(purchaserInfo))

      console.log("info", JSON.stringify(info))

    } catch (error: any) {
      console.log("error", error);
      this.showToast(error.localizedDescription, "error")
    }

  };
  showUpdateAlert = (appConfig: any) => {
    Alert.alert(
      'Update Needed',
      `Hey, there's a new version in town, please update for the best performance!`,
      [

        {
          text: 'Update',
          onPress: () => {
            Linking.openURL(Platform.OS === 'android' ? appConfig.androidApp : appConfig.iOSApp);
            setTimeout(() => {
              this.showUpdateAlert(appConfig);
            }, 1000);

          }
        },
      ],
      { cancelable: false })
  }
  setAppProp = (obj: any, callback?: any) => {
    let appConfig = { ...this.state };
    appConfig = { ...appConfig, ...obj };
    this.setState(appConfig, () => {
      if (callback) {
        callback();
      }
    });
  }

  setScreen = (screen: string, navigate?: boolean, params?: any, callback?: any) => {
    if (!this.firstScreen) {
      this.firstScreen = screen;
    } else {
      if (!navigationService.props.setHome) {
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
      }
    }
    this.setState({
      screen, routeParams: params
    }, () => {
      if (navigate) {
        navigationService.navigate(screen, params)
        this.logEvent(`navigate_${screen}`);
      }
      if (callback) {
        callback();
      }

    })
  };



  setURLOpener() {
    if (Platform.OS === 'web') {
      return;
    }
    const opener = (url: string) => {
      //Browser.open({ url })
      InAppBrowser.open(url)
    };
    const config: any = Auth.configure(null);
    config.oauth.urlOpener = opener;
    Auth.configure(config);
  }

  signOut = async () => {
    this.forceUpdate();
    this.setURLOpener();
    await Auth.signOut({ global: true });
    InAppBrowser.close();


    //this.setScreen('Home', true);

  }

  logEvent = (eventName: string) => {
    const eve = `${Platform.OS.toString().toLowerCase()}_${eventName}`;
    try {
      if (Platform.OS === "web") {
        ReactGA.event({ category: "", action: eve });
      } else {
        analytics().logEvent(eve);
      }
    } catch (e) {
      console.log("log error", JSON.stringify(e))
    }
  }

  appReview = async () => {
    if (InAppReview.isAvailable()) {
      InAppReview.RequestInAppReview()
        .then((hasFlowFinishedSuccessfully) => {
          // when return true in android it means user finished or close review flow
          console.log('InAppReview in android', hasFlowFinishedSuccessfully);

          // when return true in ios it means review flow lanuched to user.
          console.log(
            'InAppReview in ios has launched successfully',
            hasFlowFinishedSuccessfully,
          );

          // 3- another option:
          if (hasFlowFinishedSuccessfully) {
            // do something for ios
            // do something for android
          }

        })
        .catch((error) => {

          console.log("InAppReview error", error);
        });
    } else {
      console.log("InAppReview not available")
    }
  }
  error = (e: any) => {
    this.setState({ isBusy: false })
    this.showToast(e.message, 'error')
  }

  hideMenu = () => {
    const slideOutLeft = ({
      from: {
        transform: [{ translateX: 0 }], // Starts at its natural position
      },
      to: {
        transform: [{ translateX: -280 }], // Ends offscreen 100 px to the left
      },
    });
    //this.viewRef.fadeOut(1000).then(endState => setIsVisible(false));
    this.setState({ appMenuOpenedBack: false })
    navigationService.props.menuRef.animate(slideOutLeft, 200).then(() => {
      //make the navigationService.props.viewPostRef click trough
      this.setState({ appMenuOpened: false, appMenuOpenedBack: false })
    });

  }

  showToast = (message: string, type: 'error' | 'success') => {
    let tp = type.toString();
    if (Platform.OS === 'web') {
      if (tp === 'error') {
        tp = 'danger';
      }
      const options: any = {
        placement: 'top',
        type: tp,
        duration: 3000,
        animationType: 'slide-in',
        animationDuration: 300,
        successColor: colors.primaryColor,
        offsetBottom: 70,
        offsetTop: 70,
        dangerColor: "#ad2b1a",
        warningColor: "orange",
        normalColor: "gray",
        textStyle: { fontSize: 15, color: "#ffffff" },
        swipeEnabled: true
      }
      toast.show(message, options);
    } else {
      this.props.Toast.show({
        type: tp,
        position: 'top',
        topOffset: 70,
        visibilityTime: 3000,
        text1: message,

      });
    }
    //toast.show("Show custom toast", { data: { title: 'Toast title' } })
  }

  render() {
    return (
      <AppContext.Provider value={
        {
          state: this.state,
          showToast: this.showToast,
          openai: this.openai,
          shareMe: this.shareMe,
          updateUserAlias: this.updateUserAlias,
          updateUsePoints: this.updateUsePoints,
          gotoStore: this.gotoStore,
          hideMenu: this.hideMenu,
          getListItems: this.getListItems,
          appReview: this.appReview,
          buyPremium: this.buyPremium,
          buyPremiumSubscription: this.buyPremiumSubscription,
          restorePurchase: this.restorePurchase,
          playRewardedAdAndGenerate: this.playRewardedAdAndGenerate,
          createAd: this.createAd,
          logEvent: this.logEvent,
          signOut: this.signOut,
          setScreen: this.setScreen,
          setAppProp: this.setAppProp,

        }}>
        {this.props.children}
      </AppContext.Provider>)
  }
}
